
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'List',
  filters: {
    filtersStatus (value: string) {
      return value === '0'
        ? '未发布'
        : value === '1'
          ? '已发布'
          : value === '2'
            ? '下架' : ''
    },
    messageFilters (value: string) {
      return value === '0' ? '智慧导览' : value === '1' ? '游客小程序' : value === '0,1' ? '智慧导览，游客小程序' : ''
    }
  }
})
export default class List extends Vue {
  private info = {
    projectName: ''
  }

  private loading = false
  private tableData = []
  private page = 1
  private size = 10
  private total = 0

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.notice.selectMessageByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.total = res.total || 0
        this.tableData = res.list || []
      })
      .finally(() => {
        this.loading = false
      })
  }

  onAdd () {
    this.$router.push({ name: 'noticeAdd' })
  }

  onUpdate (id: string) {
    this.$router.push({ name: 'noticeUpdate', params: { id } })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios
          .post(this.$apis.notice.deleteMessage, {
            messageId: id
          })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.page = 1
            this.getData()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // 发布
  onRelease (id: string, status: string) {
    this.$axios.post(this.$apis.notice.releaseMessage, {
      messageId: id,
      status: status
    }).then(() => {
      this.$message.success('操作成功')
      this.getData()
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
